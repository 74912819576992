import React,  { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import Listaccordion from '../../components/Listaccordion';
import Accordion from '../../components/Dscy/Accordion';
import Archivegroupaccordion from '../../components/Dscy/Archivegroup/Accordion';
import { getarchivePosts } from '../../actions/journal/dscys/posts';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import Ddscyarticletemplate from '../../downloads/DS-CYS-Article_Template.doc';
import Ddscycopyrightform from '../../downloads/DS-CYS-Copyright_Form.docx';




const Archives = () => {
  const [postyear, setPostyear] = useState({ _id: "", noofvolume: "" });
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  // const cpost = useSelector((state) => state.dscysposts.posts);


const cpost = useSelector((state) => state.dscysarchivesgroup.posts);

// cpost && cpost.forEach(yearData => {
//   yearData.noofvolume.sort((a, b) => {
//     // Sort by volume
//     if (a.volume !== b.volume) {
//       return a.volume - b.volume;
//     }

//     // If volumes are the same, sort by issue
//     if (a.Issue !== b.Issue) {
//       return b.Issue - a.Issue;
//     }
//   });
// });

cpost.forEach(yearData => {
  yearData.noofvolume.sort((a, b) => {
    // Sort by volume
    if (b.volume !== a.volume) {
      return b.volume - a.volume; // Ascending order for volume
    }

    // If volumes are the same, sort by issue in descending order
    return a.Issue - b.Issue; // Descending order for issue
  });
});

useEffect(() => {
  // archivesort(cpost);
  console.log("year",moment().year());
  if (!refresh) setRefresh(true)
}, [refresh])




  const Archivegroups = () =>{
    return(
      <ul className="accordion">
    {!cpost.length ? <LinearProgress/> : cpost.map((key) => (
      <Archivegroupaccordion title={moment(key._id).format("YYYY")} content={key.noofvolume.map((voliss) => 
      <div>
        <Link
        to={{
          pathname: `/cys/archives/volume${voliss.volume}/issue${voliss.Issue}`,
          state: {
            volume: voliss.volume,
            issue: voliss.Issue,
            month_of_issue: voliss.month_of_issue
          }
        }}
      >
            volume {voliss.volume} Issue{voliss.Issue}, {voliss.month_of_issue}
      </Link>
      </div>)} />
    ))}
    </ul>
    )}

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      dispatch(getarchivePosts());
    }, [dispatch])

    useEffect(() => {
      if (cpost) setPostyear(cpost);
    }, [cpost])




  const accordionData = [{
    content:
      <div>
           <HashLink smooth to="/cys" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>           Aim and Scope</HashLink>
           <NavLink to="/cys/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
           <NavLink to="/cys/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
           <NavLink to="/cys/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
           <NavLink to="/cys/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
           <NavLink to="/cys/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
           <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
           <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
           <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
           <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
           <NavLink to="/cys/apc" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
           <a href={Ddscyarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
           <a href={Ddscycopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
      </div>
  },
  ];
//   const listaccordionData = [{
//     heading: "2023", content: <Link to="/cys/archives/volume1/issue1"> Volume1 Issue1 , July – September</Link>,

// },
// ];
  return (
    <>
      <Helmet>
          <title>Archives - DS Journal of Cyber Security (DS-CYS)</title>
          <link rel="canonical" href="https://dsjournals.com/cys/archives"/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="title" content="Archives - DS Journal of Cyber Security (DS-CYS)"/>
          <meta name="description" content="Explore past research in the field of cyber security with the archives of DS-CYS Journal. Discover new insights and perspectives from leading experts."/>
          <meta name="keywords" content="recent research paper in Cyber Security, archives of Cyber Security journal, a journal article, a journal of Cyber Security research, a Cyber Security peer reviewed journal, article for journal, journal Cyber Security, Cyber Security research, a Cyber Security journal, journal of Cyber Security research, research in Cyber Security."/>
          <meta property="og:type" content="Website"/>
          <meta property="og:site_name" content="Dream Science"/>
          <meta property="og:url" content="https://dsjournals.com/cys/archives"/>
          <meta property="og:title" content="Archives - DS Journal of Cyber Security (DS-CYS)"/>
          <meta property="og:description" content="Explore past research in the field of cyber security with the archives of DS-CYS Journal. Discover new insights and perspectives from leading experts."/>
          <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Cyber-Security.webp"/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:title" content="Archives - DS Journal of Cyber Security (DS-CYS)"/>
          <meta name="twitter:description" content="Explore past research in the field of cyber security with the archives of DS-CYS Journal. Discover new insights and perspectives from leading experts."/>
          <meta name="twitter:site" content="@DreamScience4"/>
          <meta name="twitter:url" content="https://twitter.com/DreamScience4"/>
          <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Cyber-Security.webp"/>
          <meta name="robots" content="index, follow"/>
      </Helmet>
      <section id="hero-no-slide-dscy" className="d-flex justify-center align-items-center" style={{ height: "300px" }}>
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="row justify-content-center">
                {/* <div className="col-xl-8">
                  <h2>Archive</h2>
                </div> */}
                <div className="col-lg-12">
                <h2 style={{fontSize:"25px"}}>DS Journal of Cyber Security ( DS-CYS )</h2>
                                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            {/* <h2>Archives</h2> */}
            <ol>
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/journals">Journals</Link></li>
              <li><Link to="/cys">DS-CYS</Link></li>
              <li>Archives</li>
            </ol>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="blog" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 mb-3">
                <div className="list-group">
                  <div className="accordion">
                    {accordionData.map(({ content }) => (
                      <Accordion content={content} />
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h5 style={{ alignItems: 'center' }}>Archives</h5>
                    <ul className="accordion">
                    <Archivegroups />
                      {/* {listaccordionData.map(({ heading, content }) => (
                        <Listaccordion heading={heading} content={content} />
                      ))} */}
                    </ul>
                  </div>
                </div>
                <div className="row">
                            {/*<h5>2021</h5>
                  <hr/>
                  <div className="col-md-2">
                  <Link to="/archiveslist" className="btn btn-outline-dark float-center">Issue 1</Link>
                  </div> */}

                </div>
              </div>



            </div>

          </div>

        </section>


      </main>
    </>
  )
}

export default Archives;
