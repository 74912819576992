import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import Listaccordion from '../../components/Listaccordion';
import Accordion from '../../components/Dsair/Accordion';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import Archivegroupaccordion from '../../components/Dsair/Archivegroup/Accordion';
import Ddsairarticletemplate from '../../downloads/DS-AIR-Article_Template.doc';
import Ddsaircopyrightform from '../../downloads/DS-AIR-Copyright_Form.docx';
import { getarchivePosts } from '../../actions/journal/dsair/posts';
import { CircularProgress, LinearProgress } from '@material-ui/core';


const Archives = () => {

    const [postyear, setPostyear] = useState({ _id: "", noofvolume: "" });
    const dispatch = useDispatch();
    
    const cpost = useSelector((state) => state.dsairarchivesgroup.posts);

    // cpost && cpost.forEach(yearData => {
    //     yearData.noofvolume.sort((a, b) => {
    //       // Sort by volume
    //       if (a.volume !== b.volume) {
    //         return a.volume - b.volume;
    //       }
    
    //       // If volumes are the same, sort by issue
    //       if (a.Issue !== b.Issue) {
    //         return b.Issue - a.Issue;
    //       }
    //     });
    //   });

      cpost.forEach(yearData => {
        yearData.noofvolume.sort((a, b) => {
          // Sort by volume
          if (b.volume !== a.volume) {
            return b.volume - a.volume; // Ascending order for volume
          }
    
          // If volumes are the same, sort by issue in descending order
          return a.Issue - b.Issue; // Descending order for issue
        });
    });

    useEffect(()=>{
        dispatch(getarchivePosts())
        console.log(cpost)
    },[dispatch])

    useEffect(() => {
        if (cpost)setPostyear(cpost);
      }, [cpost])


    const Archivegroups = () =>{
        return(
          <ul className="accordion">
        {/* {cpost.map((key) => ( */}
        {!cpost.length ? <LinearProgress/> : cpost.map((key) => (
          <Archivegroupaccordion title={moment(key._id).format("YYYY")} content={key.noofvolume.map((voliss) => 
          <div>
            <Link
            to={{
              pathname: `/air/archives/volume${voliss.volume}/issue${voliss.Issue}`,
              state: {
                volume: voliss.volume,
                issue: voliss.Issue,
                month_of_issue: voliss.month_of_issue
              }
            }}
          >
                volume {voliss.volume} Issue{voliss.Issue}, {voliss.month_of_issue}
          </Link>
          </div>)} />
        ))
        
        }
        </ul>
        )}

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const accordionData = [{
        content:
            <div>
                <HashLink smooth to="/air" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Aim and Scope</HashLink>
                <NavLink to="/air/editorial-board" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Editorial Board</NavLink>
                <NavLink to="/air/paper-submission" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Submission</NavLink>
                <NavLink to="/air/current-issue" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Current Issue</NavLink>
                <NavLink to="/air/archives" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Archives</NavLink>
                <NavLink to="/air/topics" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i>Topics</NavLink>
                <NavLink to="/for-authors/publication-ethics" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Publication Ethics</NavLink>
                <NavLink to="/for-authors/author-guideline" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Authors</NavLink>
                <NavLink to="/for-editors" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Editors</NavLink>
                <NavLink to="/for-reviewers" target="_blank" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Guidelines for Reviewer</NavLink>
                <NavLink to="/air/article-processing-charge" className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> APC</NavLink>
                {/* <Link to={Dsjstarticletemplate} className="list-group-item list-group-item-action" tartget="_self">Download</Link> */}
                <a href={Ddsairarticletemplate} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Paper Template</a>
                <a href={Ddsaircopyrightform} className="list-group-item list-group-item-action"><i className="bi bi-caret-right-fill"></i> Copyright Form</a>
            </div>
    },
    ];
    const listaccordionData = [
        {
            heading: "2024", content: <div><Link to="/air/archives/volume2/issue1"> Volume2 Issue1 , January – March</Link><br/></div>
        },
        {
            heading: "2023", content: <div><Link to="/air/archives/volume1/issue1"> Volume1 Issue1 , July – September</Link><br/>
                                       <Link to="/air/archives/volume1/issue2"> Volume1 Issue2 , October – December</Link>
                                  </div>
        },
    ];
    return (
        <>
            <Helmet>
            <title>Archives - DS Journal of Artificial Intelligence and Robotics</title>
            <link rel="canonical" href="https://dsjournals.com/air/archives" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="title" content="Archives - DS Journal of Artificial Intelligence and Robotics" />
            <meta name="description" content="Explore the archives of DS-RCE Journal and gain new insights into historical scientific developments. Access valuable research from your field today." />
            <meta name="keywords" content="recent research paper in Artificial Intelligence and Robotics, archives of Artificial Intelligence and Robotics journal, a journal article, a journal of Artificial Intelligence and Robotics research, an Artificial Intelligence and Robotics peer reviewed journal, article for journal, journal of Artificial Intelligence and Robotics, Artificial Intelligence and Robotics research, a Artificial Intelligence and Robotics journal, journal of Artificial Intelligence and Robotics research, research in Artificial Intelligence and Robotics." />
            <meta property="og:type" content="Website" />
            <meta property="og:site_name" content="Dream Science" />
            <meta property="og:url" content="https://dsjournals.com/air/archives" />
            <meta property="og:title" content="Archives - DS Journal of Artificial Intelligence and Robotics" />
            <meta property="og:description" content="Explore the archives of DS-RCE Journal and gain new insights into historical scientific developments. Access valuable research from your field today." />
            <meta property="og:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Artificial-Intelligence-and-Robotics.webp" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Archives - DS Journal of Artificial Intelligence and Robotics" />
            <meta name="twitter:description" content="Explore the archives of DS-RCE Journal and gain new insights into historical scientific developments. Access valuable research from your field today." />
            <meta name="twitter:site" content="@DreamScience4" />
            <meta name="twitter:url" content="https://twitter.com/DreamScience4" />
            <meta name="twitter:image" content="https://dsjournals.com/assets/img/meta/DS-Journal-of-Artificial-Intelligence-and-Robotics.webp" />
            <meta name="robots" content="index, follow" />
            </Helmet>


            <section id="hero-no-slide-dsair" className="d-flex justify-center align-items-center" style={{ height: "300px" }}>
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="row justify-content-center">
                                {/* <div className="col-xl-8">
                                    <h2>Archives</h2>
                                </div> */}
                                <div className="col-lg-12">
                                    <h2 style={{ fontSize: "25px" }}>DS Journal of Artificial Intelligence and Robotics ( DS-AIR )</h2>
                                    {/* <Link to="/" className="btn-get-started ">Read More</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        {/* <h2>Archives</h2> */}
                        <ol>
                            <li><Link to="/home">Home</Link></li>
                            <li><Link to="/journals">Journals</Link></li>
                            <li><Link to="/air">DS-AIR</Link></li>
                            <li>Archives</li>
                        </ol>
                    </div>
                </div>
            </section>

            <main id="main">
                <section className="blog" id="blog">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mb-3">
                                <div className="list-group">
                                    <div className="accordion">
                                        {accordionData.map(({ content }) => (
                                            <Accordion content={content} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <h5 style={{ alignItems: 'center' }}>Archives</h5>
                                        <ul className="accordion">
                                            {/* {listaccordionData.map(({ heading, content }) => (
                                                <Listaccordion heading={heading} content={content} />
                                            ))} */}
                                            <Archivegroups/>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    {/*<h5>2021</h5>
                    <hr/>
                    <div className="col-md-2">
                    <Link to="/archiveslist" className="btn btn-outline-dark float-center">Issue 1</Link>
                    </div> */}
                                </div>
                            </div>



                        </div>

                    </div>

                </section>


            </main>
        </>
    )
}

export default Archives;
