import React ,{useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import {Box,Typography, Grid, CircularProgress } from '@material-ui/core';
import Post from './Post/Post';
import { getcissuePosts,getcissuePost } from '../../../../actions/currentissue/posts';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};


const Posts = ({setCurrentId}) => {
   const [progress, setProgress] = useState(10); 
  const jouname = "RLS";
  const dispatch = useDispatch();
  const [postData, setPostData] = useState({ journal_title: '', volume: '', issue: '' });
  const posts = useSelector((state) =>  state.dsrlsjposts.posts);
  const cpost = useSelector((state) => (jouname ? state.cissueposts.posts.find((juname) => juname.journal_title === jouname) : null));
  
  useEffect(() => {
    dispatch(getcissuePosts())
 },[dispatch]);

 useEffect(() => {
   if (cpost) setPostData(cpost);
 }, [cpost]);

 useEffect(() => {
   dispatch(getcissuePost(jouname))
 }, [jouname])

   useEffect(() => {
     const timer = setInterval(() => {
       setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
     }, 800);
     return () => {
       clearInterval(timer);
     };
   }, []);


  return (

    posts && posts.length <=0  ? (
      <div style={{display:'flex',alignItems:'center',justifyContent:'center',padding:'50px'}}>
        <CircularProgressWithLabel value={progress}/> 
          &nbsp;&nbsp;&nbsp;&nbsp;<Typography variant="h5">Loading...</Typography>
      </div>
    ) : (
      <Grid container alignItems="stretch" spacing={3}>
                {posts.filter(key=> key.volume === postData.volume & key.issue === postData.issue).map((post) => (
               <Grid key={post._id} item xs={12} sm={12} md={12}>
               <Post post={post} setCurrentId={setCurrentId}/>
             </Grid>
            ))}
        </Grid>
    )


    // !posts.length ? <CircularProgress /> : (
    //     <Grid container alignItems="stretch" spacing={3}>
    //             {/* {posts.map((post) => ( */}
    //             {posts.filter(key=> key.volume === postData.volume & key.issue === postData.issue).map((post) => (
    //            <Grid key={post._id} item xs={12} sm={12} md={12}>
    //            <Post post={post} setCurrentId={setCurrentId}/>
    //          </Grid>
    //         ))}
    //     </Grid>
   // )
  )
}

export default Posts;